<template>
  <div>
    <v-card flat tile>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-swap-horizontal</v-icon>
          CAMBIAR GUIA DE COORDINACION
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-col cols="12">
        <v-data-table
          :headers="headerGuias"
          :items="guiasCoord"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item.opciones="{ item }">
            <v-btn
              title="Cambiar a la coordinación"
              text
              small
              @click="abrirCambioCoord(item.CABECERA_ID)"
              ><v-icon color="green" left
                >mdi-file-arrow-up-down-outline</v-icon
              ></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-card>

    <!-- DIALOG SELECCIONAR COORDINACION DESTINO -->
    <v-dialog
      v-model="dialogCoordDestino"
      max-width="600"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar height="45" dark color="blue" flat>
          <v-toolbar-title>Cambiar a </v-toolbar-title>

          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogCoordDestino = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-list round>
            <v-list-item-group
              v-for="coordab in coordAbiertas"
              v-bind:key="coordab.HCOORD_ID"
              color="primary"
            >
              <v-list-item
                target="_blank"
                @click="cambiarAcoordinacion(coordab.HCOORD_ID)"
              >
                {{ coordab.HCOORD_FECHA }}</v-list-item
              >
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "CambiarGuiaCoordiacion",
  components: {},
  props: {},
  data: () => ({
    headerGuias: [
      { text: "opciones", value: "opciones" },
      { text: "FECHA", value: "FECHA" },
      { text: "AWB", value: "AWB" },
      { text: "Cliente", value: "CLIENTE_NOMBRE" },
    ],
    options: {},
    overlay: false,
    /*datosForm: {
      ID: "",
      NOMBRE: "",
      DESCRIPCION: "",
      ESTADO: "",
    },*/
    //detalleOrigen: [],
    //listaAutocomplete: [],
    hcoordActiva: [],
    guiasCoord: [],
    guiasAbiertas: [],
    //seleccion: [],
    guiaOrigenId: "",
    hcoordDestId: "",
    itemsPerPage: 50,
    totalItems: 0,
    currentPage: 1,
    pageCount: 0,
    coordAbiertas: [],
    //guiaSelId: "",
    btnTransLoading: false,
    dialogCoordDestino: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/guias-hcoord");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.coordAbiertas = res.data.hcoord;
          this.guiasCoord = res.data.guias;
          this.guiasAbiertas = res.data.guias;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cambiarAcoordinacion(hcDes) {
      //if (this.guiaOrigenId == this.guiaSelId) {
      this.alertNotification({
        param: {
          html: "La guia de destino no puede ser igual a la de orígen",
          timer: 0,
          icon: "error",
          toast: false,
          title: "Error",
        },
      });
      this.hcoordDestId = hcDes;
      console.log(this.guiaOrigenId, this.hcoordDestId);
      // return false;
      //}

      this.setLoadingTable(true);
      this.setUrl("api/cambiar-guia-coord");
      this.requestApi({
        method: "POST",
        data: {
          cabeceraOrigenId: this.guiaOrigenId,
          hcoordDestId: this.hcoordDestId,
        },
      })
        .then((res) => {
          //this.seleccion = [];
          this.guiaOrigenId = "";
          this.hcoordDestId = "";
          this.dialogCoordDestino = false;
          this.cargarLista();
          this.setLoadingTable(false);
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 5000,
              icon: "success",
              toast: true,
              title: "Exito",
            },
          });
          //this.cargarDetalleGuiaOrigen();
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirCambioCoord(hcId) {
      this.guiaOrigenId = hcId;
      this.dialogCoordDestino = true;
    },
    handlePageChange() {
      this.cargarLista();
    },
  },
  mounted() {
    this.cargarDatos();
    this.setTitleToolbar("Cambiar guia de coordinación");
  },
};
</script>
